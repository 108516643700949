<template>
  <div>
    <userSheet
      v-if="sahabat"
    />
    <h3 class="headline grey--text text-xs-center hidden-sm-and-down mt-3">
      Dashboard
    </h3>
    <div
      v-show="loading"
      class="text-xs-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
      <v-progress-circular
        indeterminate
        color="red"
      />
      <v-progress-circular
        indeterminate
        color="purple"
      />
      <v-progress-circular
        indeterminate
        color="green"
      />
      <v-progress-circular
        indeterminate
        color="amber"
      />
    </div>
    <info
      v-if="info.length > 0"
      v-model="info"
    />
    <news />
  </div>
</template>

<script>
import { createGetParams } from '@/rest'

export default {
  components: {
    Info: () => import(/* webpackChunkName: "info" */ '@/components/Info'),
    News: () => import(/* webpackChunkName: "news" */ '@/components/News'),
    UserSheet: () => import(/* webpackChunkName: "usersheet" */ '@/components/UserSheet'),
  },
  data: () => ({
    info: [],
    loading: false,
    sahabat: false,
  }),
  mounted: function () {
    this.getDashboardInfoItems()
    this.$rest.get('getDealerResources.php', createGetParams({})).then(function (response) {
      this.sahabat = response.data.sahabat
    }.bind(this)).catch(error => {
      console.log(error)
    })
  },
  methods: {
    getDashboardInfoItems: function () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getDashboardInfoItems.php', params)
        .then(function (response) {
          this.info = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>

<style>
  .v-sheet--offset {
    top: -16px;
    position: relative;
  }
  .v-progress-circular {
    margin: 1rem
  }
</style>
